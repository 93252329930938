import { createContext } from "react";
import EventHandler from "../Events";
import ApiRequest, { urlBase64ToUint8Array } from "../../Components/ApiRequest";
import DB from "../DB";

export default class Data {
    eventHandler: EventHandler;
    apiRequest: ApiRequest;
    db: DB;

    constructor(eventHandler: EventHandler, apiRequest: ApiRequest) {
        this.eventHandler = eventHandler;
        this.apiRequest = apiRequest;
        this.db = new DB();

        this.eventHandler.on('network:change', this.networkChange.bind(this));

        this.eventHandler.on('holidays:request', this.requestHolidays.bind(this));
        this.eventHandler.on('holidays:request-remote', this.requestRemoteHolidays.bind(this));
        this.eventHandler.on('holidays:request-local', this.requestLocalHolidays.bind(this));
        this.eventHandler.on('holidays:update-local', this.updateLocalHolidays.bind(this));
        this.eventHandler.on('holidays:update-remaining', this.updateRemainingHolidays.bind(this));
        this.eventHandler.on('holidays:submit', this.submitHolidayRequest.bind(this));

        this.eventHandler.on('resources:request', this.requestResources.bind(this));
        this.eventHandler.on('resources:request-remote', this.requestRemoteResources.bind(this));
        this.eventHandler.on('resources:request-local', this.requestLocalResources.bind(this));
        this.eventHandler.on('resources:update-local', this.updateLocalResources.bind(this));

        this.eventHandler.on('profile:request', this.requestProfile.bind(this));
        this.eventHandler.on('profile:request-remote', this.requestRemoteProfile.bind(this));
        this.eventHandler.on('profile:request-local', this.requestLocalProfile.bind(this));
        this.eventHandler.on('profile:update-local', this.updateLocalProfile.bind(this));

        this.eventHandler.on('notifications:request', this.requestNotifications.bind(this));
        this.eventHandler.on('notifications:push-check', this.resetPushNotifications.bind(this));
        this.eventHandler.on('notifications:request-remote', this.requestRemoteNotifications.bind(this));
        this.eventHandler.on('notifications:request-local', this.requestLocalNotifications.bind(this));
        this.eventHandler.on('notifications:update-local', this.updateLocalNotifications.bind(this));
        this.eventHandler.on('notification:request', this.requestNotification.bind(this));
        this.eventHandler.on('notification:request-remote', this.requestRemoteNotification.bind(this));
        this.eventHandler.on('notification:request-local', this.requestLocalNotification.bind(this));
        this.eventHandler.on('notification:update-local', this.updateLocalNotification.bind(this));

        this.eventHandler.on('actions:sync', this.syncActions.bind(this));

        this.eventHandler.on('logout', this.logout.bind(this));
    }

    getApi() {
        return this.apiRequest;
    }

    async networkChange(online: any) {
        if (online) {
            this.eventHandler.trigger('actions:sync');
        }
    }

    async requestHolidays() {
        if (!this.apiRequest.authentication.check() || !this.apiRequest.authentication.hasPermissions()) {
            return;
        }
        this.eventHandler.trigger('holidays:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteHolidays() {
        try {
            const body = await this.apiRequest.get('/holidays');
            this.eventHandler.trigger('holidays:update-remaining', body.remaining_holidays);
            this.eventHandler.trigger('holidays:update-local', body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote holidays, reverting to local holidays.');
            this.eventHandler.trigger('holidays:request-local');
        }
    }

    async requestLocalHolidays() {
        const holidays = await this.db.getAll('holidays');
        this.eventHandler.trigger('holidays:receive', holidays, 'local');
    }

    async updateLocalHolidays(holidays: any, from: any) {
        await this.db.clear('holidays');
        await this.db.bulkPut('holidays', holidays);
        this.eventHandler.trigger('holidays:receive', holidays, from);
    }

    async updateRemainingHolidays(remaining: any) {
        // let profile = this.apiRequest.authentication.profile();
        // profile.remaining_holidays = remaining;
        // this.apiRequest.authentication.setProfile(profile);
        await this.db.update('profile', this.apiRequest.authentication.userId(), {
            remaining_holidays: remaining
        });
    }

    async submitHolidayRequest(starts_at: any, ends_at: any) {
        if (navigator.onLine) {
            await this.apiRequest.post('/holidays', {
                starts_at,
                ends_at
            });
        }
        await this.requestHolidays();
    }


    async requestResources() {
        if (!this.apiRequest.authentication.check() || !this.apiRequest.authentication.hasPermissions()) {
            return;
        }
        this.eventHandler.trigger('resources:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteResources() {
        try {
            const body = await this.apiRequest.get('/resources');
            this.eventHandler.trigger('resources:update-local', body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote resources, reverting to local resources.');
            this.eventHandler.trigger('resources:request-local');
        }
    }

    async requestLocalResources() {
        const resources = await this.db.getAll('resources');
        this.eventHandler.trigger('resources:receive', resources, 'local');
    }

    async updateLocalResources(resources: any, from: any) {
        await this.db.clear('resources');
        await this.db.bulkPut('resources', resources);
        this.eventHandler.trigger('resources:receive', resources, from);
    }


    async requestProfile() {
        if (!this.apiRequest.authentication.check() || !this.apiRequest.authentication.hasPermissions()) {
            return;
        }
        this.eventHandler.trigger('profile:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteProfile() {
        try {
            const body = await this.apiRequest.get('/profile');
            this.eventHandler.trigger('profile:update-local', body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote profile, reverting to local profile.');
            this.eventHandler.trigger('profile:request-local');
        }
    }

    async requestLocalProfile() {
        const profile = await this.db.get('profile', this.apiRequest.authentication.userId());
        this.eventHandler.trigger('profile:receive', profile, 'local');
    }

    async updateLocalProfile(profile: any, from: any) {
        if (!this.apiRequest.authentication.userId()) {
            this.apiRequest.authentication.setUserId(profile.id);
        }
        await this.db.put('profile', profile);
        this.eventHandler.trigger('profile:receive', profile, from);
    }


    async requestNotifications() {
        if (!this.apiRequest.authentication.check() || !this.apiRequest.authentication.hasPermissions()) {
            return;
        }
        this.eventHandler.trigger('notifications:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async resetPushNotifications() {
      if ('Notification' in window && Notification.permission === 'granted' && navigator.onLine) {
        // Ensure the server has the latest push subscription.
        navigator.serviceWorker.getRegistration().then((registration: any) => {
          registration.pushManager.getSubscription().then((subscription: any) => {
            this.apiRequest.post('/register-push', subscription);
          }).catch(() => {
            registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_KEY || 'BKBAegtea0h174qt5bN8EtksU03EnuoW7NdShxcmjF9PyB0LYaIPA1R4DEIBRDl5fyi1z07U2ZMFi0PhRUSmEKY')
            }).then((subscription: any) => {
              this.apiRequest.post('/register-push', subscription);
            });
          });
        });
      }
    }

    async requestRemoteNotifications() {
        try {
            const body = await this.apiRequest.get('/notifications');
            this.eventHandler.trigger('notifications:update-local', body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote notifications, reverting to local notifications.');
            this.eventHandler.trigger('notifications:request-local');
        }
    }

    async requestLocalNotifications() {
        const notifications = await this.db.getAll('notifications');
        this.eventHandler.trigger('notifications:receive', notifications, 'local');
    }

    async updateLocalNotifications(notifications: any, from: any) {
        await this.db.clear('notifications');
        await this.db.bulkPut('notifications', notifications);
        this.eventHandler.trigger('notifications:receive', notifications, from);
    }

    async requestNotification(notificationId: any) {
        // this.eventHandler.trigger('notification:request-' + (navigator.onLine ? 'remote' : 'local'), notificationId);
        this.eventHandler.trigger('notification:request-local', notificationId);
    }

    async requestRemoteNotification(notificationId: any) {
        try {
            const body = await this.apiRequest.get('/notifications/' + notificationId);
            this.eventHandler.trigger('notification:update-local', notificationId, body.data, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote notification, reverting to local notification.');
            this.eventHandler.trigger('notification:request-local', notificationId);

        }
    }

    async requestLocalNotification(notificationId: any) {
        const notification = await this.db.get('notifications', {id: notificationId});
        this.eventHandler.trigger('notification:receive:' + notificationId, notification);
    }

    async updateLocalNotification(notificationId: any, changes: any, createAction: boolean = false) {
        await this.db.update('notifications', notificationId, changes);
        if (createAction) {
            await this.db.add('actions', {type: 'notification', notificationId, changes});
            this.eventHandler.trigger('actions:sync');
        }
        this.eventHandler.trigger('notifications:request-local', notificationId);
    }


    async syncActions() {
        if (navigator.onLine) {
            const actions = await this.db.getAll('actions');
            if (actions.length) {
                actions.forEach(async (action: any) => {
                    try {
                        switch (action.type) {
                            case 'notification':
                                await this.apiRequest.put('/notifications/' + action.notificationId, action.changes);
                                break;
                            default:
                                return true;
                        }
                        this.db.delete('actions', Number.parseInt(action.id));
                    } catch (e) {
                        console.error('Failed to sync action: ' + action.id);
                    }
                });
            }
        }
        if (this.apiRequest.authentication.check()) {
            this.eventHandler.trigger('holidays:request');
        }
    }


    async logout() {
        this.apiRequest.authentication.logout();
        await this.db.flush();
        await this.db.init();
    }
}

export const DataContext = createContext(null as any);