import { SyntheticEvent, useContext, useEffect, useState, useCallback, useRef } from 'react';
import Logo from '../assets/images/logo-2.png';
import './index.scss';
import Modal from '../Components/Modal';
import Busy from '../Components/Busy';
import { AuthenticationContext } from '../Contexts/Authentication';
import { useNavigate, useLocation } from 'react-router-dom';
import { registerHandlers, unRegisterHandlers } from '../Components/ErrorHandler';
import { DataContext } from '../Contexts/Data';

function ForgottenPassword() {

  const [recoveryState, setRecoveryState] = useState({
    error: false,
    busy: false
  });

  const location = useLocation();
  const navigation = useRef(useNavigate());
  const authentication = useContext(AuthenticationContext);
  const dataLayer = useContext(DataContext);

  const showError = useCallback((e: any) => {
    setRecoveryState({ ...recoveryState, busy: false, error: true });
  }, [recoveryState]);

  useEffect(() => {
    const handle = registerHandlers(showError);
    return () => {
      unRegisterHandlers(handle);
    }
  }, [showError]);

  useEffect(() => {
    if (!recoveryState.busy && authentication.check()) {
      navigation.current('/');
    }
  }, [recoveryState, authentication, location, navigation]);

  const submit = (e: SyntheticEvent) => {
    e.preventDefault();

    setRecoveryState({ ...recoveryState, busy: true });

    const fd = new FormData(e.target as HTMLFormElement);

    dataLayer.getApi().post('/reset', fd).then((body: any) => { 
      navigation.current('/login', {
        state: {
          busy: false,
          error: false,
          notification: {
            title: 'Password Reset',
            message: body.message
          }
        }
      });
    }).catch((e: any) => {
      showError(e);
    });
  }

  return (
    <div className="ForgottenPassword">
      {recoveryState.error && (
        <Modal onDismiss={() => setRecoveryState({ ...recoveryState, error: false })} title="Error">Unable to find an account with the details provided.<br /><br />Please try again.</Modal>
      )}
      <img src={Logo} className="ForgottenPasswordLogo" alt="NWR Hygiene Group" width="250" height="58" />
      <form method="post" onSubmit={(e) => submit(e)} className="LoginForm">
        <input type="text" autoComplete="email" disabled={recoveryState.busy} name="email" id="email" placeholder="Email address" />
        <button disabled={recoveryState.busy}>
          {recoveryState.busy ? <Busy /> : 'Request Reset'}
        </button>
        <button type="button" onClick={() => navigation.current('/login')}>Login</button>
      </form>
    </div>
  );
}

export default ForgottenPassword;
