import { useContext } from 'react';
import './index.scss';
import { DataContext } from '../Contexts/Data';
import { urlBase64ToUint8Array } from '../Components/ApiRequest';

function Debug() {
    const dataLayer = useContext(DataContext);
    const apiRequest = dataLayer.getApi();

    const build_version = process.env.REACT_APP_VERSION || '0.0.0';
    const build_date = process.env.REACT_APP_BUILD_DATE || 'N/A';

    const sendTestPushNotification = async () => {
        await apiRequest.post('/push-test', {});
    }

    const reloadApp = () => {
        window.location.reload();
    }

    const resetPushNotifications = async () => {
        if ('Notification' in window && Notification.permission === 'granted' && navigator.onLine) {
            // Ensure the server has the latest push subscription.
            navigator.serviceWorker.getRegistration().then((registration: any) => {
                registration.pushManager.getSubscription().then((subscription: any) => {
                    dataLayer.getApi().post('/register-push', subscription);
                }).catch(() => {
                    registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_KEY || 'BKBAegtea0h174qt5bN8EtksU03EnuoW7NdShxcmjF9PyB0LYaIPA1R4DEIBRDl5fyi1z07U2ZMFi0PhRUSmEKY')
                    }).then((subscription: any) => {
                        dataLayer.getApi().post('/register-push', subscription);
                    });
                });
            });
        }
    }

    return (
        <div className="Debug">
            <p><strong>Build Version</strong><br />{build_version}</p>
            <p><strong>Build Date</strong><br />{build_date}</p>
            {'Notification' in window && (
                <p><button type="button" onClick={sendTestPushNotification}>Test Push Notification</button><button className="Alt" type="button" onClick={resetPushNotifications}>Reset Push Notifications</button></p>
            )}
            <button type="button" onClick={reloadApp}>Restart App</button>
        </div>
    );
}

export default Debug;
