import { SyntheticEvent, useContext, useEffect, useState, useCallback, useRef } from 'react';
import Logo from '../assets/images/logo-2.png';
import './index.scss';
import Modal from '../Components/Modal';
import Busy from '../Components/Busy';
import { AuthenticationContext } from '../Contexts/Authentication';
import { useNavigate, useLocation } from 'react-router-dom';
import { registerHandlers, unRegisterHandlers } from '../Components/ErrorHandler';
import Notification from '../Components/Notification';
import { DataContext } from '../Contexts/Data';
import { EventContext } from '../Contexts/Events';

function Login() {

  const location = useLocation();
  const navigation = useRef(useNavigate());
  const authentication = useContext(AuthenticationContext);
  const dataLayer = useContext(DataContext);
  const eventHandler = useContext(EventContext);

  const [authenticationState, setAuthenticationState] = useState({
    error: false,
    busy: false,
    notification: false as any,
    ...location.state
  });

  const showError = useCallback((e: any) => {
    setAuthenticationState({ ...authenticationState, busy: false, error: true, notification: false });
  }, [authenticationState]);

  useEffect(() => {
    const handle = registerHandlers(showError);
    return () => {
      unRegisterHandlers(handle);
    }
  }, [showError]);

  useEffect(() => {
    if (!authenticationState.busy) {
      if (authentication.check()) {
        navigation.current('/');
      }
      if (location.pathname === '/') {
        navigation.current('/login');
      }
    }
  }, [authenticationState, authentication, location, navigation]);

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();

    setAuthenticationState({ ...authenticationState, busy: true, error: false, notification: false });

    const fd = new FormData(e.target as HTMLFormElement);
    fd.append('mode', (authentication.token() ? 'check' : 'create'));

    try {
      const token = await dataLayer.getApi().post('/tokens/create', fd);
      authentication.login(token.access_token, token.expires);
      eventHandler.trigger('profile:request');

      navigation.current('/');
    } catch (e) {
      showError(e);
    }
  }

  return (
    <div className="Login">
      {authenticationState.error && (
        <Modal onDismiss={() => setAuthenticationState({ ...authenticationState, error: false })} title="Login Failed">Unable to find an account with the details provided.<br /><br />Please try again.</Modal>
      )}
      {authenticationState.notification !== false && (
        <Notification onDismiss={() => setAuthenticationState({ ...authenticationState, notification: false })} title={authenticationState.notification.title}>{authenticationState.notification.message}</Notification>
      )}
      <img src={Logo} className="LoginLogo" alt="NWR Hygiene Group" width="250" height="58" />
      <form method="post" onSubmit={(e) => submit(e)} className="LoginForm">
        <input type="text" autoComplete="email" disabled={authenticationState.busy} name="email" id="email" placeholder="Email address" />
        <input type="password" autoComplete="password" disabled={authenticationState.busy} name="password" id="password" placeholder="Password" />
        <button disabled={authenticationState.busy}>
          {authenticationState.busy ? <Busy /> : 'Log In'}
        </button>
        <button type="button" onClick={() => navigation.current('/forgotten-password')}>Forgotten password?</button>
      </form>
    </div>
  );
}

export default Login;
