import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../Contexts/Authentication';
import { registerHandlers, unRegisterHandlers } from '../Components/ErrorHandler';
import Modal from '../Components/Modal';
import { DataContext } from '../Contexts/Data';
import { urlBase64ToUint8Array } from '../Components/ApiRequest';

function Setup() {
    const [setupState, setSetupState] = useState({
        busy: true,
        error: false as any,
        continue: !('Notification' in window),
        location: false,
        notification: false
    });

    const navigation = useRef(useNavigate());
    const authentication = useContext(AuthenticationContext);
    const loading = useRef(true);
    const dataLayer = useContext(DataContext);
    const apiRequest = dataLayer.getApi();

    const showError = useCallback((e: any) => {
        setSetupState({ ...setupState, busy: false, error: true });
    }, [setupState]);

    useEffect(() => {
      const handle = registerHandlers(showError);
      return () => {
        unRegisterHandlers(handle);
      }
    }, [showError]);

    useEffect(() => {
        if (authentication.hasPermissions()) {
            navigation.current('/');
            return;
        }
        if (loading.current) {
            loading.current = false;
        }
    }, [setSetupState, setupState, apiRequest, loading, showError, authentication]);

    useEffect(() => {
        if (!setupState.busy) {
            if (authentication.onboarded()) {
                navigation.current('/');
            }
        }
    }, [setupState, authentication, navigation]);

    const requestNotificationAccess = async () => {
        try {
            const registration = await navigator.serviceWorker.getRegistration();
            if (!registration) {
                throw new Error('Permission Denied');
            }
            const result = await Notification.requestPermission();
            if (result !== 'granted') {
                throw new Error('Permission Denied');
            }
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_KEY || 'BKBAegtea0h174qt5bN8EtksU03EnuoW7NdShxcmjF9PyB0LYaIPA1R4DEIBRDl5fyi1z07U2ZMFi0PhRUSmEKY')
            });
            await apiRequest.post('/register-push', subscription);
            setSetupState((state) => ({...state, continue: true, notification: true}));
        } catch (e) {
            setSetupState((state) => ({...state, continue: false, error: {
                title: 'Permission Denied',
                message: 'Permission to send you notifications has been denied.'
            }}));
        }
    }

    const proceed = () => {
        authentication.setPermissions({
            location: true,
            notifications: ('Notification' in window)
        });
        navigation.current('/');
    }

    return (
        <div className="Setup">
            {setupState.error && (
                <Modal onDismiss={() => setSetupState({ ...setupState, error: false })} title="Setup Failed">There was a problem processing your request.<br /><br />Please try again.</Modal>
            )}
            <h1>Permissions</h1>
            <p>To proceed with using the NWR Hygiene Group app, you must provide the required permissions below.</p>
            {'Notification' in window && (
                <>
                    <h2>Notifications</h2>
                    <p>We may need to send you notifications regarding the shifts you are assigned.</p>
                    <button type="button" className="PermissionButton" disabled={setupState.notification} onClick={requestNotificationAccess}>{(setupState.notification ? 'Notifications Granted' : 'Allow Notifications')}</button>
                </>
            )}
            {!('Notification' in window) && (
                <>
                    <h2>Notifications</h2>
                    <p>Your device does not support push notifications.</p>
                </>
            )}
            <button type="button" className="Button" onClick={proceed} disabled={!setupState.continue}>Continue</button>
        </div>
    );
}

export default Setup;
