import './index.scss';
import Logo from '../assets/images/logo-2.png';

function Installed() {
    return (
        <div className="Installed">
            <img src={Logo} className="InstallLogo" alt="NWR Hygiene Group" width="250" height="58" />
            <p>Thank you for installing the app.</p>
            <p>You can now close this screen and open the app via your app drawer/home screen.</p>
        </div>
    );
}

export default Installed;
